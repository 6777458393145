import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { changeGuest, changeRoomType } from '@redux/actions'
import * as selectors from '@redux/selectors'
import { useQueryParams } from './useQueryParams'

const selector = createSelector(
  selectors.getAccordion,
  selectors.getRoomType,
  (accordion, roomType) => ({
    accordion,
    roomType,
  })
)

export const useGuestsAccordion = () => {
  const dispatch = useDispatch()
  const { roomType } = useSelector(selector)
  const { addQueryParameter, removeQueryParameter, removeQueryParametersArray } = useQueryParams()

  const updateRouteQuery = (value: string | null) => {
    if (value) {
      addQueryParameter('guest', value)
    } else {
      removeQueryParametersArray(['guest', 'visitStart', 'visitEnd'])
    }
  }

  const removeRoomType = () => {
    dispatch(changeRoomType(null))
    removeQueryParameter('roomType')
  }

  const validateRoomTypeForGuest = (value: number) => {
    // remove room type selected as it changes number of beds
    if (roomType?.beds && roomType.beds !== value) {
      removeRoomType()
    }
  }

  const onChangeGuest = (value: number | null) => {
    if (value) {
      validateRoomTypeForGuest(value)
    }
    dispatch(changeGuest(value))
    updateRouteQuery(value?.toString() || null)
  }

  const onInitGuestValidation = (value: number | null) => {
    if (value) {
      validateRoomTypeForGuest(value)
    }
  }

  return {
    onChangeGuest,
    onInitGuestValidation,
  }
}
