import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { useCloseWithEscKey, useOnClickOutside } from '../helpers'
import BasicButton from './BasicButton'
import CloseIcon from './CloseIcon'

type DialogProps = {
  onClose: () => void
  open?: boolean
  title: React.ReactNode
}

const Dialog: React.FC<React.PropsWithChildren<DialogProps>> = ({
  children,
  onClose,
  open = false,
  title,
}) => {
  const wrapperRef = React.useRef<HTMLInputElement>(null)

  useOnClickOutside(wrapperRef, onClose)
  useCloseWithEscKey(onClose)

  React.useEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      if (open) {
        body.classList.add('overflow-hidden')
      } else {
        body.classList.remove('overflow-hidden')
      }
    }
    return () => {
      const body = document.querySelector('body')
      if (body) {
        body.classList.remove('overflow-hidden')
      }
    }
  }, [open])

  if (!open) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      aria-labelledby="modal-title"
      aria-modal="true"
      className="relative z-10 inset-0"
      role="dialog"
    >
      <div className="fixed inset-0 bg-gray bg-opacity-50 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto overscroll-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8">
            <div className="bg-white p-8" ref={wrapperRef}>
              <div className="flex justify-between mb-6 text-black">
                <h3 className="text-subcategory select-none">{title}</h3>
                <BasicButton className="hover:text-primary-main" clearBasicStyle onClick={onClose}>
                  <CloseIcon />
                </BasicButton>
              </div>
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default Dialog
