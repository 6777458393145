import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { AccordionEnum, IVisitCategory, IVisitType } from '@interfaces/data'
import { changeVisitTypeCategory, setVisitTypeDialogData } from '@redux/actions'
import { basicSelector, getLang, resolveNextAccordion } from '../../helpers'
import { useAccordion } from '../../hooks/useAccordion'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useVisitTypeCategoriesAccordion } from '../../hooks/useVisitTypeCategoriesAccordion'
import Accordion from '../Accordion'
import AccordionTitle from '../AccordionTitle'
import { useAppContext } from '../AppContext'
import VisitTypeCategories from './VisitTypeCategories'

const VisitTypeCategoriesAccordion: React.FunctionComponent = () => {
  const { language } = useAppContext()
  const { accordion, isFetching, visitCategories, visitType, visitTypeCategory } =
    useSelector(basicSelector)
  const { routerQueryParams } = useQueryParams()
  const { onChangeAccordion } = useAccordion()
  const { onChangeVisitType } = useVisitTypeCategoriesAccordion()

  const dispatch = useDispatch()

  const onLoadVisitType = (value: IVisitType | null) => {
    dispatch(setVisitTypeDialogData(value))
  }

  const onChangeVisitTypeCategory = (value: IVisitCategory | null) => {
    if (value) {
      const nextAccordion = resolveNextAccordion(accordion as AccordionEnum)
      onChangeAccordion(nextAccordion)
    }
    dispatch(changeVisitTypeCategory(value))
  }

  const isLoading = !!routerQueryParams.visitType && isFetching
  const isActive = isLoading || !!visitType

  const visitTypeTitle: React.ReactNode = React.useMemo(() => {
    if (visitType) {
      return getLang(visitType?.name, language)
    } else {
      return (
        <FormattedMessage
          defaultMessage="Type of Stay"
          description="Type of Stay accordion title with unknown type"
        />
      )
    }
  }, [visitType, language])

  return (
    <Accordion
      active={isActive}
      isLoading={isLoading}
      onClear={() => {
        onChangeVisitTypeCategory(null)
        onChangeVisitType(null)
      }}
      onClose={() => onChangeAccordion(null)}
      onOpen={() => onChangeAccordion(AccordionEnum.TYPE_OF_STAY)}
      open={accordion === AccordionEnum.TYPE_OF_STAY}
      title={<AccordionTitle isLoading={isLoading}>{visitTypeTitle}</AccordionTitle>}
    >
      <VisitTypeCategories
        onLoadVisitType={onLoadVisitType}
        onSelectVisitType={onChangeVisitType}
        onSelectVisitTypeCategory={onChangeVisitTypeCategory}
        selectVisitType={visitType}
        selectVisitTypeCategory={visitTypeCategory}
        visitCategories={visitCategories}
      />
    </Accordion>
  )
}

export default VisitTypeCategoriesAccordion
