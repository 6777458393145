import * as React from 'react'
import { IHotel } from '@interfaces/data'
import { getPrice } from '../../helpers'
import { useAppContext } from '../AppContext'
import Card from '../Card/Card'
import Stars from '../Stars'

export type HotelItemProps = {
  data: IHotel
  onLoadHotel?: () => void
  onSelect?: React.MouseEventHandler<HTMLButtonElement>
  selected?: boolean
}

const HotelItem: React.FunctionComponent<HotelItemProps> = ({
  data,
  onLoadHotel = () => {},
  onSelect = () => {},
  selected = false,
}) => {
  const { language } = useAppContext()
  return (
    <Card
      imageUrl={data.image}
      onDetail={onLoadHotel}
      onSelect={onSelect}
      pretitle={
        <Stars className={selected ? 'text-white' : 'text-primary-main'} value={data.stars || 0} />
      }
      price={getPrice(data.lowest_price, 'amount_per_night', language)}
      selected={selected}
      title={data.name}
    />
  )
}
export default HotelItem
