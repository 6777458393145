import clsx from 'classnames'
import { addDays, format, isSameDay, isSameMonth, isWithinInterval } from 'date-fns'
import { cs, de, enGB, ru } from 'date-fns/locale'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  CalendarModeEnum,
  CalendarStateEnum,
  ICurrency,
  IDate,
  IDateCheckout,
  ILanguage,
  ITravelDate,
} from '@interfaces/data'
import {
  changeAccordion,
  changeCalendarDate,
  loadDates,
  loadDatesCheckout,
  setCalendarMode,
  setCalendarTravelDate,
  setTravelDate,
} from '@redux/actions'
import { DATE_FORMAT_YYYY_MM_DD } from '../../constants'
import {
  basicSelector,
  dateRangesCacheKeys,
  getDateKeysForCache,
  getPrice,
  getRangeForCalendarDatesRequest,
  useCloseWithEscKey,
} from '../../helpers'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useAppContext } from '../AppContext'
import FormattedPrice from '../FormattedPrice'
import Skeleton from '../Skeleton'

export const useLocale = () => {
  const { language } = useAppContext()
  if (language === 'cs') {
    return cs
  }
  if (language === 'de') {
    return de
  }
  if (language === 'ru') {
    return ru
  }
  return enGB
}

export const useCalendarControls = () => {
  const dispatch = useDispatch()
  const { calendarTravelDate } = useSelector(basicSelector)

  const onSelect = React.useCallback(
    (travelDate: ITravelDate) => {
      dispatch(setCalendarTravelDate(travelDate))
    },
    [dispatch]
  )

  const onClickAway = React.useCallback(() => {
    if (calendarTravelDate.from || calendarTravelDate.to) {
      dispatch(setTravelDate({ from: null, to: null }))
      onSelect({ from: null, to: null })
    }
  }, [calendarTravelDate.from, calendarTravelDate.to, dispatch, onSelect])

  useCloseWithEscKey(onClickAway)

  return {
    onClickAway,
    onSelect,
  }
}

export const useCalendar = () => {
  const dispatch = useDispatch()
  const {
    appInitialized,
    calendarDate,
    calendarMode,
    calendarPrice,
    calendarState,
    calendarTravelDate,
    dates,
    datesCheckout,
    hotel,
    isDatesFetching,
    lowestPrice,
    roomType,
    visitType,
  } = useSelector(basicSelector)
  const dateRangesCacheKeysSelector = useSelector(dateRangesCacheKeys)

  const { addQueryParameter } = useQueryParams()
  const { onSelect } = useCalendarControls()

  const onChangeDate = React.useCallback(
    (value: Date) => dispatch(changeCalendarDate(value)),
    [dispatch]
  )

  const onSelectDate = React.useCallback(
    (date: Date) => {
      if (calendarMode === CalendarModeEnum.FROM_DATE) {
        onSelect({ from: date, to: null })
      } else if (calendarMode === CalendarModeEnum.TO_DATE) {
        onSelect({ from: calendarTravelDate.from, to: date })
      }
    },
    [calendarMode, calendarTravelDate.from, onSelect]
  )

  const updateRouteQuery = ({ from, to }: ITravelDate) => {
    if (!from || !to) return

    const parsedFrom = format(from, DATE_FORMAT_YYYY_MM_DD)
    const parsedTo = format(to, DATE_FORMAT_YYYY_MM_DD)
    addQueryParameter('visitStart', parsedFrom)
    addQueryParameter('visitEnd', parsedTo)
  }

  const onConfirm = React.useCallback(() => {
    if (calendarTravelDate.from && calendarTravelDate.to) {
      dispatch(setTravelDate(calendarTravelDate))
      updateRouteQuery(calendarTravelDate)
      dispatch(changeAccordion(null))
    }
  }, [calendarTravelDate, dispatch, updateRouteQuery])

  // Load days
  React.useEffect(() => {
    if (appInitialized) {
      const isCalendarInitial = calendarState === CalendarStateEnum.INITIAL

      let sendRequest = true
      let dateFrom
      let dateTo
      let dateMiddleCacheKey
      let dateToCacheKey

      if (!isCalendarInitial) {
        const {
          dateFromCalendarDate,
          dateMiddleCalendarDate,
          dateToCalendarDate,
          sendRequest: willSendRequest,
        } = getRangeForCalendarDatesRequest(calendarDate, dateRangesCacheKeysSelector, false)

        const {
          dateFromCalendarDateRequest,
          dateMiddleCalendarDateCacheKey,
          dateToCalendarDateCacheKey,
          dateToCalendarDateRequest,
        } = getDateKeysForCache(dateFromCalendarDate, dateMiddleCalendarDate, dateToCalendarDate)

        dateFrom = dateFromCalendarDateRequest
        dateTo = dateToCalendarDateRequest
        dateMiddleCacheKey = dateMiddleCalendarDateCacheKey
        dateToCacheKey = dateToCalendarDateCacheKey

        sendRequest = willSendRequest
      }

      if (sendRequest) {
        dispatch(
          loadDates({
            date_from: dateFrom,
            date_to: dateTo,
            dateMiddleCalendarDateCacheKey: dateMiddleCacheKey,
            dateToCalendarDateCacheKey: dateToCacheKey,
            hotel_id: hotel?.id || undefined,
            initial: isCalendarInitial,
            room_type_id: roomType?.id || undefined,
            visit_type_id: visitType?.id || undefined,
          })
        )
      }
    }
  }, [
    appInitialized,
    calendarDate,
    calendarState,
    dispatch,
    hotel?.id,
    roomType?.id,
    visitType?.id,
    dateRangesCacheKeysSelector,
  ])

  // Load end days and switch calendar mode
  React.useEffect(() => {
    if (appInitialized && calendarTravelDate.from && calendarTravelDate.to === null) {
      dispatch(setCalendarMode(CalendarModeEnum.TO_DATE))
      dispatch(
        loadDatesCheckout({
          hotel_id: hotel?.id || undefined,
          room_type_id: roomType?.id || undefined,
          start_date: format(calendarTravelDate.from, DATE_FORMAT_YYYY_MM_DD),
          visit_type_id: visitType?.id || undefined,
        })
      )
    } else if (appInitialized) {
      dispatch(setCalendarMode(CalendarModeEnum.FROM_DATE))
    }
  }, [
    appInitialized,
    calendarTravelDate.from,
    calendarTravelDate.to,
    dispatch,
    hotel?.id,
    roomType?.id,
    visitType?.id,
  ])

  return {
    checkouts: datesCheckout,
    data: dates,
    date: calendarDate,
    isFetching: isDatesFetching,
    isFinalPrice: Boolean(
      visitType && hotel && roomType && calendarTravelDate.from && calendarTravelDate.to
    ),
    lowestPrice,
    mode: calendarMode,
    onChangeDate,
    onConfirm,
    onSelectDate,
    price: calendarPrice,
    travelDate: calendarTravelDate,
  }
}

const generateDatesForCurrentWeek = (
  date: Date,
  data: IDate[] = [],
  checkouts: IDateCheckout[] = [],
  mode: CalendarModeEnum,
  travelDate: ITravelDate,
  activeDate: Date,
  onSelectDate: (date: Date) => void,
  onClickAway: () => void,
  isFetching = false,
  currency: ICurrency,
  language: ILanguage
): JSX.Element[] => {
  let currentDate = date
  const week = []

  for (let day = 0; day < 7; day++) {
    const cloneDate = currentDate

    const currentDateFormat = format(currentDate, DATE_FORMAT_YYYY_MM_DD)

    const findData = data.find((item) => item.date === currentDateFormat)
    const isSameCurrentMonth = isSameMonth(currentDate, activeDate)
    const isAvailabilityWithPrice = findData && findData?.price && isSameCurrentMonth
    const isNotAvailable = findData?.availability === 'NOT_AVAILABLE'

    const isDateDisabled =
      !findData ||
      !isSameCurrentMonth ||
      (mode === CalendarModeEnum.FROM_DATE && !findData?.selectable) ||
      (mode === CalendarModeEnum.TO_DATE &&
        checkouts.filter((item) => item.date === currentDateFormat).length == 0)

    week.push(
      <div
        className={clsx('day', {
          available: findData?.availability === 'AVAILABLE' && isSameCurrentMonth,
          demand: findData?.availability === 'ON_DEMAND' && isSameCurrentMonth,
          disabledDate: isDateDisabled,
          from: travelDate.from ? isSameDay(currentDate, travelDate.from) : false,
          isNotSameMonth: !isSameCurrentMonth,
          notAvailable: isNotAvailable && isSameCurrentMonth,
          select:
            travelDate.from && travelDate.to
              ? isWithinInterval(currentDate, { end: travelDate.to, start: travelDate.from })
              : false,
          to: travelDate.to ? isSameDay(currentDate, travelDate.to) : false,
          today: isSameDay(currentDate, new Date()),
        })}
        key={currentDateFormat}
        onClick={(event) => {
          event.stopPropagation()

          if (isDateDisabled) {
            onClickAway()
          } else {
            onSelectDate(cloneDate)
          }
        }}
      >
        <span>{format(currentDate, 'd')}</span>
        {isFetching && isSameCurrentMonth && <Skeleton width={32} />}
        {!isFetching && (
          <div className="price">
            {isAvailabilityWithPrice ? (
              <>
                {!(findData.price?.is_final || false) && (
                  <span className="mr-0.5">
                    <FormattedMessage
                      defaultMessage="from"
                      description="from prefix in price in day on the calendar"
                    />
                  </span>
                )}
                <span>
                  <FormattedPrice value={getPrice(findData.price, 'amount', language)} />
                </span>
              </>
            ) : (
              <>
                {isNotAvailable ? (
                  <span>
                    <FormattedMessage
                      defaultMessage="N/A"
                      description="Description for not available pill"
                    />
                  </span>
                ) : (
                  <div className="h-4"></div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    )
    currentDate = addDays(currentDate, 1)
  }
  return week
}

export const getWeeks = (
  startDate: Date,
  endDate: Date,
  data: IDate[] = [],
  checkouts: IDateCheckout[] = [],
  mode: CalendarModeEnum,
  travelDate: ITravelDate,
  activeDate: Date,
  onSelectDate: (date: Date) => void,
  onClickAway: () => void,
  isFetching = false,
  currency: ICurrency,
  language: ILanguage
): JSX.Element[][] => {
  let currentDate = startDate
  const allWeeks = []
  while (currentDate <= endDate) {
    allWeeks.push(
      generateDatesForCurrentWeek(
        currentDate,
        data,
        checkouts,
        mode,
        travelDate,
        activeDate,
        onSelectDate,
        onClickAway,
        isFetching,
        currency,
        language
      )
    )
    currentDate = addDays(currentDate, 7)
  }
  return allWeeks
}
