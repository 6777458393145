import { useDispatch, useSelector } from 'react-redux'
import { useAppContext } from '@components/AppContext'
import { AccordionEnum, IVisitType } from '@interfaces/data'
import { changeHotel, changeRoomType, changeVisitType } from '@redux/actions'
import * as selectors from '@redux/selectors'
import { getKeyLang, resolveNextAccordion } from '../helpers'
import { useAccordion } from './useAccordion'
import { useQueryParams } from './useQueryParams'

export const useVisitTypeCategoriesAccordion = () => {
  const { language } = useAppContext()
  const { addQueryParameter, removeQueryParameter, removeQueryParametersArray } = useQueryParams()
  const dispatch = useDispatch()
  const hotel = useSelector(selectors.getHotel)
  const accordion = useSelector(selectors.getAccordion)

  const { onChangeAccordion } = useAccordion()

  const removeRoomHotel = () => {
    dispatch(changeHotel(null))
    dispatch(changeRoomType(null))
    removeQueryParametersArray(['hotel', 'roomType'])
  }

  const updateRouteQuery = (value: string | null | undefined) => {
    if (value) {
      addQueryParameter('visitType', value)
    } else {
      removeQueryParameter('visitType')
    }
  }

  const validateVisitType = (value: IVisitType) => {
    // check if hotel is allowed in the visit type, if not remove the hotel and room selection
    if (hotel && !value.hotels.some((item) => item.id === hotel.id)) {
      removeRoomHotel()
    }
  }

  const onChangeVisitType = (value: IVisitType | null) => {
    if (value) {
      validateVisitType(value)
      const nextAccordion = resolveNextAccordion(accordion as AccordionEnum)
      onChangeAccordion(nextAccordion)
    }
    dispatch(changeVisitType(value))
    const visitTypeName = value?.name[getKeyLang(language)]
    updateRouteQuery(visitTypeName)
  }

  const onInitVisitTypeValidation = (value: IVisitType | null) => {
    if (value) {
      validateVisitType(value)
    } else {
      removeQueryParameter('visitType')
    }
  }

  return {
    onChangeVisitType,
    onInitVisitTypeValidation,
  }
}
