import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ITravelDate } from '@interfaces/data'
import { resetCalendar } from '@redux/actions'
import * as selectors from '@redux/selectors'
import { useQueryParams } from './useQueryParams'

export const useCalendarAccordion = () => {
  const dispatch = useDispatch()
  const { removeQueryParametersArray } = useQueryParams()

  const travelDate: ITravelDate = useSelector(selectors.getTravelDate)
  const step = useSelector(selectors.getStep)
  const appInitialized = useSelector(selectors.getAppInitialized)

  useEffect(() => {
    if (appInitialized && step === 1 && !travelDate.from && !travelDate.to) {
      removeQueryParametersArray(['visitStart', 'visitEnd'])
    }
  }, [travelDate, step])

  const removeTravelDate = () => {
    dispatch(resetCalendar())
  }

  return {
    removeTravelDate,
  }
}
