import clsx from 'classnames'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { addLoadedImage } from '@redux/actions'
import * as selectors from '@redux/selectors'
import FormattedPrice from '../FormattedPrice'
import Image from '../Image'
import Skeleton from '../Skeleton'
import Typography from '../Typography'
import styles from './Card.module.scss'
import CardDetailLink from './CardDetailLink'

type CardType = {
  description?: React.ReactNode
  imageUrl?: string | 'loading'
  onDetail?: () => void
  onSelect: React.MouseEventHandler<HTMLButtonElement>
  pretitle?: React.ReactNode
  price?: number
  selected?: boolean
  title: React.ReactNode
  variant?: 'normal' | 'loading'
}

const Card: React.FunctionComponent<CardType> = ({
  description = null,
  imageUrl = null,
  onDetail = null,
  onSelect,
  pretitle = null,
  price = null,
  selected = false,
  title,
  variant = 'normal',
}) => {
  const [pictureLoading, setPictureLoading] = React.useState<boolean>(true)
  const dispatch = useDispatch()
  const images = useSelector(selectors.getImages)

  const onImageLoad = () => {
    setPictureLoading(false)
    dispatch(addLoadedImage())
  }

  const arePicturesLoading =
    variant === 'loading' || (imageUrl && images.loading !== images.loaded && pictureLoading)

  return (
    <button
      className={clsx(styles.root, {
        [styles.rootLoading]: arePicturesLoading,
        [styles.rootSelected]: selected,
      })}
      onClick={onSelect}
      type="button"
    >
      {imageUrl && (
        <div className={styles.rootImageWrapper} style={{ height: '160px' }}>
          <Image
            height={0}
            onLoad={onImageLoad}
            src={imageUrl !== 'loading' ? imageUrl : undefined}
            style={{
              height: arePicturesLoading ? 0 : '100%',
            }}
          />
          <Skeleton
            className={styles.rootImageSkeleton}
            style={{
              height: arePicturesLoading ? '100%' : 0,
            }}
          />
        </div>
      )}
      <div className="w-full flex-1 px-4 py-5 md:px-6 md:py-8 relative h-full">
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="flex flex-col text-left w-11/12 md:auto">
            {pretitle && <div className="mb-2">{pretitle}</div>}
            <Typography component="h3" variant="subcategory">
              {title}
            </Typography>
            {description && <div>{description}</div>}
          </div>
          <div className="flex flex-row md:flex-col items-center md:items-end text-right mt-3 md:mt-0">
            {price !== null ? (
              <>
                <Typography className="font-medium">
                  <FormattedMessage
                    defaultMessage="<small>from</small> {price}"
                    description="From price"
                    values={{
                      price: <FormattedPrice value={price} />,
                      small: (chunks) => <small>{chunks}</small>,
                    }}
                  />
                </Typography>
                <Typography
                  className="text-gray md:uppercase before:content-['/'] before:px-1"
                  component="span"
                  variant="caption"
                >
                  <FormattedMessage defaultMessage="night" description="Price for night" />
                </Typography>
              </>
            ) : (
              <>
                <Skeleton width="80%" />
                <Skeleton width="50%" />
              </>
            )}
          </div>
        </div>
        {onDetail && <CardDetailLink onClick={onDetail} />}
      </div>
    </button>
  )
}

export default Card
