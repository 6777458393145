import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AccordionEnum } from '@interfaces/data'
import { changeAccordion } from '@redux/actions'
import { useQueryParams } from './useQueryParams'

export const useAccordion = () => {
  const dispatch = useDispatch()
  const { routerQueryParams } = useQueryParams()

  const onChangeAccordion = useCallback((value: AccordionEnum | null) => {
    dispatch(changeAccordion(value))
  }, [])

  const resolveAccordionFromRouteQuery = () => {
    const lastFilledAccordion = [
      routerQueryParams.guest,
      routerQueryParams.visitType,
      routerQueryParams.hotel,
      routerQueryParams.roomType,
      routerQueryParams.visitStart && routerQueryParams.visitEnd,
    ].findIndex((value) => !value)

    const accordionFromRouteQuery = lastFilledAccordion === -1 ? null : lastFilledAccordion

    return accordionFromRouteQuery
  }

  return {
    onChangeAccordion,
    resolveAccordionFromRouteQuery,
  }
}
